import {useMemo} from 'react';
import type {
  DynamicComponent,
  DynamicComponentDictionary,
  ModuleProperties,
  SlotRenderer,
} from '../types/dynamic-component';

/**
 * Signature for rendering slot layouts.
 */
type SlotChildrenRenderer = (
  Component: SlotRenderer,
  slots: DynamicComponentDictionary | undefined,
  renderFn?: Render
) => JSX.Element[];

/**
 * Rendering function for rendering a member of a Layout's slots.
 * @param Component the React functional component to produce a JSX.Element
 * @param details the current slot member to be rendered
 * @param members the list of sibling slot members
 */
export type Render = (
  Component: SlotRenderer,
  details: DynamicComponent<string, ModuleProperties>,
  members: DynamicComponent<string, ModuleProperties>[]
) => JSX.Element;

/**
 * Default handling for rendering a member of a Layout's slots.
 * @param Component the React functional component to produce a JSX.Element
 * @param details the current slot member to be rendered
 */
const defaultRender: Render = (Component, details) => (
  <Component
    key={`${details.path.join(':')}:${details.mid}`}
    {...details}
    style={`${details.style ?? ''};`}
  />
);

/**
 * Flattens the members of `slots` and applies the supplied `renderFn` to every
 * member of `slots`.
 */
const renderChildren: SlotChildrenRenderer = (
  Component,
  slots,
  renderFn = defaultRender
) => {
  const {items, ...children} = slots ?? {items: []};
  const components = Object.values(children)
    .flatMap((element) => {
      if (Array.isArray(element)) {
        return element;
      } else if (typeof element !== 'undefined') {
        return [element];
      } else {
        return [];
      }
    })
    .concat(items ?? []);
  return components.map((component) =>
    renderFn(Component, component, components)
  );
};

/**
 * Applies the given `Component` to each member of each of the `slots`. If the
 * `renderFn` is provided it will be called for each member of `slots` with the
 * `Component` and the `DynamicComponent` in the slot. If `renderFn` is not
 * provided a default implementation will be used.
 *
 * **NOTE** `renderFn` if provided must be a _stable reference_ or every call
 * to the `useRenderedChildren` hook with re-render the tree.
 */
export const useRenderedChildren: SlotChildrenRenderer = (
  Component,
  slots,
  renderFn = defaultRender
) =>
  useMemo(
    () => renderChildren(Component, slots, renderFn),
    [Component, renderFn, slots]
  );
